import axios from 'axios';

const API_KEY = 'a96049d5-107c-49ab-ae1f-d4d80a2ae4a4';
const API_URL = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest';

export const getBitcoinPrice = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
    return response.data.bitcoin.usd;
  } catch (error) {
    console.error('Error fetching Bitcoin price:', error);
    return null;
  }
};